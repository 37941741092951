import WasmController from "../../../../frameworks/WasmController";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import PreAnestheticLogList from "issara-sdk/apis/PreAnestheticLogList_apps_ANS";
import PreAnestheticRecordDetail from "issara-sdk/apis/PreAnestheticRecordDetail_apps_ANS";
import PreAnestheticRecordList from "issara-sdk/apis/PreAnestheticRecordList_apps_ANS";
import PreAnestheticRecordByPatientDetail from "issara-sdk/apis/PreAnestheticRecordByPatientDetail_apps_ANS";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import EmployeeDetailByUsername from "issara-sdk/apis/EmployeeDetailByUsername_users";

import { getListWithKey, removeListWithKey } from "../../ORM/sequence/ORRequest";

export type State = {
  ORRequestSequence?: any;
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  searchedItemListWithKey?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PreAnestheticSequence?: {
    sequenceIndex?: string | null;

    //FormID
    preAnestheticHistoryDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    preAnestheticAirwayDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    preAnestheticBloodDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    preAnestheticProblemsDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    preAnestheticRiskDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    preAnestheticReEvaluationRespDetail?: {
      id?: string;
      edit_user_name?: any;
      edited?: any;
      status?: string;
    };
    id?: number | null;
    data?: {
      // CardPreAnestheticFormUX
      PreanestheticAssessmentAt?: string;
      PreanestheticAssessmentAtRemark?: string;
      BodyWeight?: string;
      Height?: string;
      BMI?: string;
      BP?: string;
      PsychologicalStatus?: string;
      PsychologicalStatusRemark?: string;
      Pulse?: string;
      RR?: string;
      PsychologicalStatusTemp?: string;
      O2SatRA?: string;
      DX?: string;
      ProposedOperation?: string;
      Surgeon?: string;
      DateTime?: string;
      Anesthesiologist?: string;
      AnesthesiologistLicenseNumber?: string;
      AnesthesiologistCode?: string;
      AnesthesiaNurse?: any[];
      // CardPreAnestheticHistoryUX
      PreviousAnesthesia?: string;
      PreviousAnesthesiaRemark?: string;
      Complication?: string;
      ComplicationRemark?: string;
      KnownDisease?: string;
      KnownDiseaseRemark?: string;
      FamilyHistory?: string;
      FamilyHistoryRemark?: string;
      CurrentMedications?: string;
      CurrentMedicationsRemark?: string;
      HabitIsNon?: boolean;
      HabitAlcohol?: boolean;
      HabitAlcoholRemark?: string;
      HabitSmoking?: boolean;
      HabitSmokingRemark?: string;
      HabitDrugAbuse?: boolean;
      HabitDrugAbuseRemark?: string;
      HabitHerbSupplement?: boolean;
      HabitHerbSupplementRemark?: string;
      FunctionalClass?: string;
      FunctionalLMP?: string;
      FunctionalIsContactLens?: string;
      // CardPreAnestheticAirwayEvaluationUX
      MallampatiGrade?: string;
      ThyromentalDistance?: string;
      MouthOpening?: string;
      PatencyOfNares?: string;
      ProminentIncisor?: string;
      LimitationOfHeadNeckMotility?: string;
      DifficultIntubationExpected?: string;
      Comment?: string;
      ArtificialAirway?: string;
      ArtificialAirwayIsCommentTube?: string;
      Dental?: string;
      Respiratory?: string;
      RespiratoryRemark?: string;
      Cardiovascular?: string;
      CardiovascularRemark?: string;
      Neurologic?: string;
      NeurologicRemark?: string;
      EENT?: string;
      EENTRemark?: string;
      GI?: string;
      GIRemark?: string;
      Genitourinary?: string;
      GenitourinaryRemark?: string;
      EndocrineAndMetabolic?: string;
      EndocrineAndMetabolicRemark?: string;
      SkinBreast?: string;
      SkinBreastRemark?: string;
      ImmunologyHematology?: string;
      ImmunologyHematologyRemark?: string;
      // CardPreAnestheticBloodChemistryUX
      HbTime?: string;
      HcTime?: string;
      PltTime?: string;
      WBCTime?: string;
      PTTime?: string;
      PTTTime?: string;
      INRTime?: string;
      NaTime?: string;
      KTime?: string;
      ClTime?: string;
      CO2Time?: string;
      BunTime?: string;
      CrTime?: string;
      GFRTime?: string;
      BSTIME?: string;
      ASTTime?: string;
      ALTTime?: string;
      AlpTime?: string;
      AlbTime?: string;
      OthersTime?: string;
      CXR?: string;
      EKG?: string;
      Imaging?: string;
      // CardPreAnestheticProblemsConsiderationsUX
      AnestheticProblemsConsiderationsRemark?: string;
      ASAPSClassification?: number;
      IsEmergency?: boolean;
      IsAnestheticInformedConsent?: boolean;
      NPO?: string;
      AnestheicPlan?: string;
      AlternativeOfAnesthesiaDiscussed?: string;
      PremedicationNone?: boolean;
      PremedicationNoneRemark?: any;
      PremedicationNoneRemarkOther?: string;
      BloodPrepared?: string;
      BloodPreparedStatus?: boolean;
      PlanOfPostoperativeCare?: string;
      AmbulatoryPatientWithCompanion?: string;
      PlanOfPostoperativePainManagement?: string;
      PlanOfPostoperativePainManagementRemark?: string;
      // CardPreAnestheticRiskUX
      SoreThroat?: boolean;
      Hoarseness?: boolean;
      Nausea?: boolean;
      Vomiting?: boolean;
      Pruritus?: boolean;
      UrinaryRetention?: boolean;
      VentilatorySupport?: boolean;
      RiskOfAnesthesiaIncludedOther?: boolean;
      RiskOfAnesthesiaIncludedOtherRemark?: string;
      RiskOfAnesthesiaIncludedComment?: string;
      // CardPreAnestheticReEvaluationUX
      PreanestheticReEvaluationNoneDueto?: boolean;
      PreanestheticReEvaluationIsNoneDueto?: string;
      PREANESTHETICREEVALUATIONDateTime?: string;
      PREANESTHETICREEVALUATIONDate?: string;
      PREANESTHETICREEVALUATIONTime?: string;
      PREANESTHETICREEVALUATIONBodyWeight?: string;
      PREANESTHETICREEVALUATIONBP?: string;
      PreanestheticReEvaluationIsPR?: string;
      PreanestheticReEvaluationIsRR?: string;
      PreanestheticReEvaluationTemp?: string;
      PreanestheticReEvaluationLMP?: string;
      PreanestheticReEvaluationProposedOperation?: string;
      IsChangeInClinicalCondition?: string;
      ChangeInClinicalConditionRemark?: string;
      IsChangeInCurrentMedication?: string;
      ChangeInCurrentMedicationRemark?: string;
      IsNewDiseaseIllness?: string;
      NewDiseaseIllnessRemark?: string;
      AssessmentIsStableClinicalCondition?: boolean;
      AssessmentIsUnstableDueTo?: boolean;
      AssessmentUnstableDueToRemark?: string;
      PlanIsGoOnAnesthesiaAsPlan?: boolean;
      PlanIsOther?: boolean;
      PlanOtherRemark?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetist?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetistId?: number | null;
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode?: string;
      PreanestheticReEvaluationAnesthesiaNurseId?: number | null;
      PreanestheticReEvaluationAnesthesiaNurse?: string;
      PreanestheticReEvaluationAnesthesiaNurseLicenseNumber?: string;
      PreanestheticReEvaluationAnesthesiaNurseCode?: string;
    };
    status_label?: string;
    modLogInfo?: { open: boolean; logList: any[] };
    // anesPhysicianEvaluation
    anesPhysicianEvaluation?: {
      id?: number;
      edit_user_name?: any;
      edited?: any;
      // systemicEvaluation
      systemicEvaluationType?: any;
      respiratoryCheck?: boolean;
      respiratoryDetail?: string;
      cardiovascularCheck?: boolean;
      cardiovascularDetail?: string;
      neurologicCheck?: boolean;
      neurologicDetail?: string;
      eentCheck?: boolean;
      eentDetail?: string;
      giCheck?: boolean;
      giDetail?: string;
      genitourinaryCheck?: boolean;
      genitourinaryDetail?: string;
      endrocrineCheck?: boolean;
      endrocrineDetail?: string;
      skinCheck?: boolean;
      skinDetail?: string;
      immunologyCheck?: boolean;
      immunologyDetail?: string;
      // labData
      labDataType?: any;
      labDataDetail?: any;
      labDataDate?: any;
      // investigations
      xRayCheck?: boolean;
      xRayDetail?: string;
      imagingCheck?: boolean;
      imagingDetail?: string;
      ekgCheck?: boolean;
      ekgDetail?: string;
      ekgDate?: any;
      otherCheck?: boolean;
      otherDetail?: string;
      // AirwayTeethHeadNeck
      dental?: any;
      denturesCheck?: boolean;
      capsCrownsCheck?: boolean;
      overBiteCheck?: boolean;
      looseTeethCheck?: boolean;
      dentalOtherCheck?: boolean;
      dentalOtherDetail?: string;
      mallampatiGradeType?: any;
      thyromentalDistance?: any;
      mouthOpening?: any;
      patencyOfNares?: any;
      prominentIncisor?: any;
      limitation?: any;
      dificultIntubation?: any;
      dificultIntubationComment?: any;
      // Planned anesthesia
      gaCheck?: boolean;
      tivaCheck?: boolean;
      ivSedateCheck?: boolean;
      macCheck?: boolean;
      regionalCheck?: boolean;
      plannedOtherCheck?: boolean;
      plannedOtherDetail?: string;
      // Airway Management
      raeOralCheck?: boolean;
      raeNasalCheck?: boolean;
      reinforceCheck?: boolean;
      maskCheck?: boolean;
      tracheostomyCheck?: boolean;
      lmaCheck?: boolean;
      airwayOtherCheck?: boolean;
      airwayOtherDetail?: string;
      // Intubation Assessment
      standardCheck?: boolean;
      blindNasalCheck?: boolean;
      styletCheck?: boolean;
      fiberopticCheck?: boolean;
      videolaryngoscopeCheck?: boolean;
      intubationOtherCheck?: boolean;
      intubationOtherDetail?: string;
      // ASA PS Classification
      classification?: any;
      emergencyCheck?: any;
      // Anesthetic problems / considerations
      considerations?: string;
      // Plan of postoperative pain management
      management?: any;
    };
    clinicalTerm?: Record<string, any>;
  } | null;
};

export const PRE_ANESTHETIC_RE_EVALUATION = {
  PREANESTHETICREEVALUATIONDateTime: "",
  PREANESTHETICREEVALUATIONDate: "",
  PREANESTHETICREEVALUATIONTime: "",
  PREANESTHETICREEVALUATIONBodyWeight: "",
  PREANESTHETICREEVALUATIONBP: "",
  PreanestheticReEvaluationIsPR: "",
  PreanestheticReEvaluationIsRR: "",
  PreanestheticReEvaluationTemp: "",
  PreanestheticReEvaluationLMP: "",
  PreanestheticReEvaluationProposedOperation: "",
  IsChangeInClinicalCondition: "",
  ChangeInClinicalConditionRemark: "",
  IsChangeInCurrentMedication: "",
  ChangeInCurrentMedicationRemark: "",
  IsNewDiseaseIllness: "",
  NewDiseaseIllnessRemark: "",
  AssessmentIsStableClinicalCondition: false,
  AssessmentIsUnstableDueTo: false,
  AssessmentUnstableDueToRemark: "",
  PlanIsGoOnAnesthesiaAsPlan: false,
  PlanIsOther: false,
  PlanOtherRemark: "",
};

export const StateInitial: State = {
  PreAnestheticSequence: {
    sequenceIndex: null,

    //FormID
    preAnestheticHistoryDetail: {
      id: "",
    },
    preAnestheticAirwayDetail: {
      id: "",
    },
    preAnestheticBloodDetail: {
      id: "",
    },
    preAnestheticProblemsDetail: {
      id: "",
    },
    preAnestheticRiskDetail: {
      id: "",
    },
    preAnestheticReEvaluationRespDetail: {
      id: "",
    },
    id: null,
    data: {
      // CardPreAnestheticFormUX
      PreanestheticAssessmentAt: "",
      PreanestheticAssessmentAtRemark: "",
      BodyWeight: "",
      Height: "",
      BMI: "",
      BP: "",
      PsychologicalStatus: "",
      PsychologicalStatusRemark: "",
      Pulse: "",
      RR: "",
      PsychologicalStatusTemp: "",
      O2SatRA: "",
      DX: "",
      ProposedOperation: "",
      Surgeon: "",
      DateTime: "",
      Anesthesiologist: "",
      AnesthesiologistLicenseNumber: "",
      AnesthesiologistCode: "",
      AnesthesiaNurse: [],
      // CardPreAnestheticHistoryUX
      PreviousAnesthesia: "",
      PreviousAnesthesiaRemark: "",
      Complication: "",
      ComplicationRemark: "",
      KnownDisease: "",
      KnownDiseaseRemark: "",
      FamilyHistory: "",
      FamilyHistoryRemark: "",
      CurrentMedications: "",
      CurrentMedicationsRemark: "",
      HabitIsNon: false,
      HabitAlcohol: false,
      HabitAlcoholRemark: "",
      HabitSmoking: false,
      HabitSmokingRemark: "",
      HabitDrugAbuse: false,
      HabitDrugAbuseRemark: "",
      HabitHerbSupplement: false,
      HabitHerbSupplementRemark: "",
      FunctionalClass: "",
      FunctionalLMP: "",
      FunctionalIsContactLens: "",
      // CardPreAnestheticAirwayEvaluationUX
      MallampatiGrade: "",
      ThyromentalDistance: "",
      MouthOpening: "",
      PatencyOfNares: "",
      ProminentIncisor: "",
      LimitationOfHeadNeckMotility: "",
      DifficultIntubationExpected: "",
      Comment: "",
      ArtificialAirway: "none",
      ArtificialAirwayIsCommentTube: "",
      Dental: "",
      Respiratory: "",
      RespiratoryRemark: "",
      Cardiovascular: "",
      CardiovascularRemark: "",
      Neurologic: "",
      NeurologicRemark: "",
      EENT: "",
      EENTRemark: "",
      GI: "",
      GIRemark: "",
      Genitourinary: "",
      GenitourinaryRemark: "",
      EndocrineAndMetabolic: "",
      EndocrineAndMetabolicRemark: "",
      SkinBreast: "",
      SkinBreastRemark: "",
      ImmunologyHematology: "",
      ImmunologyHematologyRemark: "",
      // CardPreAnestheticBloodChemistryUX
      HbTime: "",
      HcTime: "",
      PltTime: "",
      WBCTime: "",
      PTTime: "",
      PTTTime: "",
      INRTime: "",
      NaTime: "",
      KTime: "",
      ClTime: "",
      CO2Time: "",
      BunTime: "",
      CrTime: "",
      GFRTime: "",
      BSTIME: "",
      ASTTime: "",
      ALTTime: "",
      AlpTime: "",
      AlbTime: "",
      OthersTime: "",
      CXR: "",
      EKG: "",
      Imaging: "",
      // CardPreAnestheticProblemsConsiderationsUX
      AnestheticProblemsConsiderationsRemark: "",
      IsEmergency: false,
      IsAnestheticInformedConsent: false,
      NPO: "",
      AnestheicPlan: "",
      AlternativeOfAnesthesiaDiscussed: "",
      PremedicationNone: false,
      PremedicationNoneRemark: [],
      PremedicationNoneRemarkOther: "",
      BloodPrepared: "",
      BloodPreparedStatus: false,
      PlanOfPostoperativeCare: "",
      AmbulatoryPatientWithCompanion: "",
      PlanOfPostoperativePainManagement: "",
      PlanOfPostoperativePainManagementRemark: "",
      // CardPreAnestheticRiskUX
      SoreThroat: false,
      Hoarseness: false,
      Nausea: false,
      Vomiting: false,
      Pruritus: false,
      UrinaryRetention: false,
      VentilatorySupport: false,
      RiskOfAnesthesiaIncludedOther: false,
      RiskOfAnesthesiaIncludedOtherRemark: "",
      RiskOfAnesthesiaIncludedComment: "",
      // CardPreAnestheticReEvaluationUX
      PreanestheticReEvaluationNoneDueto: false,
      PreanestheticReEvaluationIsNoneDueto: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetist: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetistId: null,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode: "",
      PreanestheticReEvaluationAnesthesiaNurseId: null,
      PreanestheticReEvaluationAnesthesiaNurse: "",
      PreanestheticReEvaluationAnesthesiaNurseLicenseNumber: "",
      PreanestheticReEvaluationAnesthesiaNurseCode: "",
      ...PRE_ANESTHETIC_RE_EVALUATION,
    },
    status_label: "",
    modLogInfo: { open: false, logList: [] },
    // anesPhysicianEvaluation
    anesPhysicianEvaluation: {
      // systemicEvaluation
      systemicEvaluationType: "",
      respiratoryCheck: false,
      respiratoryDetail: "",
      cardiovascularCheck: false,
      cardiovascularDetail: "",
      neurologicCheck: false,
      neurologicDetail: "",
      eentCheck: false,
      eentDetail: "",
      giCheck: false,
      giDetail: "",
      genitourinaryCheck: false,
      genitourinaryDetail: "",
      endrocrineCheck: false,
      endrocrineDetail: "",
      skinCheck: false,
      skinDetail: "",
      immunologyCheck: false,
      immunologyDetail: "",
      // labData
      labDataType: "",
      labDataDetail: "",
      labDataDate: "",
      // investigations
      xRayCheck: false,
      xRayDetail: "",
      imagingCheck: false,
      imagingDetail: "",
      ekgCheck: false,
      ekgDetail: "",
      ekgDate: "",
      otherCheck: false,
      otherDetail: "",
      // AirwayTeethHeadNeck
      dental: "",
      denturesCheck: false,
      capsCrownsCheck: false,
      overBiteCheck: false,
      looseTeethCheck: false,
      dentalOtherCheck: false,
      dentalOtherDetail: "",
      mallampatiGradeType: "",
      thyromentalDistance: "",
      mouthOpening: "",
      patencyOfNares: "",
      prominentIncisor: "",
      limitation: "",
      dificultIntubation: "",
      dificultIntubationComment: "",
      // Planned anesthesia
      gaCheck: false,
      tivaCheck: false,
      ivSedateCheck: false,
      macCheck: false,
      regionalCheck: false,
      plannedOtherCheck: false,
      plannedOtherDetail: "",
      // Airway Management
      raeOralCheck: false,
      raeNasalCheck: false,
      reinforceCheck: false,
      maskCheck: false,
      tracheostomyCheck: false,
      lmaCheck: false,
      airwayOtherCheck: false,
      airwayOtherDetail: "",
      // Intubation Assessment
      standardCheck: false,
      blindNasalCheck: false,
      styletCheck: false,
      fiberopticCheck: false,
      videolaryngoscopeCheck: false,
      intubationOtherCheck: false,
      intubationOtherDetail: "",
      // ASA PS Classification
      classification: "",
      emergencyCheck: false,
      // Anesthetic problems / considerations
      considerations: "",
      // Plan of postoperative pain management
      management: "",
    },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

export const SEARCH_KEYS = {
  USER_ANESTHETIST: "User_Anesthetist",
  DOCTOR_ANESTHESIOLOGIST: "Doctor_Anesthesiologist",
};

const SEARCH_INDEXES = {
  [SEARCH_KEYS.USER_ANESTHETIST]: "CI",
  [SEARCH_KEYS.DOCTOR_ANESTHESIOLOGIST]: "I",
} as const;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const FORM_DATA_ACTION: any = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
};

export const GetMaster: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        // CardPreAnestheticFormUX
        ["drugName", {}],
        ["preanes_assessment_at", {}],
        ["preanes_psychological_status", {}],
        ["doctor", {}],
        ["divisionWard", {}],
        // CardPreAnestheticHistoryUX
        ["preanes_previous", {}],
        ["preanes_complication", {}],
        ["preanes_known_disease", {}],
        ["preanes_family_history", {}],
        ["preanes_current_med", {}],
        ["preanes_functional_class", {}],
        ["preanes_contact_lens", {}],
        // CardPreAnestheticAirwayEvaluationUX
        ["preanes_mallampati", {}],
        ["preanes_thyromental", {}],
        ["preanes_mouth_opening", {}],
        ["preanes_patency_nares", {}],
        ["preanes_prominent", {}],
        ["preanes_limitation", {}],
        ["preanes_difficult", {}],
        ["preanes_respiratory", {}],
        ["preanes_cardiovascul", {}],
        ["preanes_neurologic", {}],
        ["preanes_EENT", {}],
        ["preanes_gi", {}],
        ["preanes_genitourinar", {}],
        ["preanes_Endocrine", {}],
        ["preanes_skin_breast", {}],
        ["preanes_Immunology", {}],
        // CardPreAnestheticProblemsConsiderationsUX
        ["anes_plan_of_postope", {}],
        ["preanes_plan_of_post", {}],
        // CardPreAnestheticReEvaluationUX
        ["preanes_change_clini", {}],
        ["preanes_change_in_cu", {}],
        ["preanes_new_disease", {}],
        ["asa_class", {}],
      ],
    },
  });

  controller.setState(
    {
      PreAnestheticSequence: {
        ...StateInitial.PreAnestheticSequence,
        sequenceIndex: "Action",
        data: {
          ...state.PreAnestheticSequence?.data,
        },
        anesPhysicianEvaluation: {
          ...StateInitial.PreAnestheticSequence?.anesPhysicianEvaluation,
        },
        clinicalTerm: state.ORRequestSequence?.clinicalTerm,
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );

  // #Action(controller, { ...params, action: "FETCH_PRE_ANESTHETIC" });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_PRE_ANESTHETIC") {
    if (!state.selectedOrOrder) {
      return;
    }
    const [preRecordResp, preRecordErr] = await PreAnestheticRecordByPatientDetail.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter_id: state.selectedOrOrder?.encounter,
        // patient_id: state.selectedOrOrder?.patient_id,
        form_code: "CardPreanestheticAnesthesiaRecord",
        form_version: params.formVersion,
      },
    });

    const promiseArray = [
      "CardPreAnestheticHistory",
      "CardPreAnestheticAirwayEvaluation",
      "CardPreAnestheticBloodChemistry",
      "CardPreAnestheticProblemsConsiderations",
      "CardPreAnestheticRisk",
      "CardPreAnestheticReEvaluation",
      "CardPhysicianEvaluation",
    ].map((formCode) =>
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: formCode,
          form_version: params.formVersion,
        },
      })
    );

    const [
      [formHistoryResp],
      [formAirwayResp],
      [formBloodResp],
      [formProblemsResp],
      [formRiskResp],
      [formReEvaluationResp],
      [formPhysicianResp],
    ] = await Promise.all(promiseArray);

    // set vital_signs
    const measurementData = await GetMeasurementData(controller, params);

    // set anesthesiologist
    const [operatingOrder] = await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.id,
    });

    // #let anesthesiologist = null;
    // let anesthesiologistLicense = null;
    // let anesthesiologistCode = null;

    // #const [doctorResp, doctorErr, doctorNet] = await DoctorDetail.retrieve({
    //   apiToken: controller.apiToken,
    //   pk: getOperatingOrder[0]?.anesthesia_teams[0]?.anesthesiologist,
    // });

    // #const [employeeResp, employeeErr, employeeNet] =
    //   await UserEmployeeDetailAPIView.retrieve({
    //     pk: doctorResp?.user,
    //     apiToken: controller.apiToken,
    //     extra: { division: controller.data.division },
    //   });

    // #let license = null;
    // if (employeeResp?.code) {
    //   const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
    //     apiToken: controller.apiToken,
    //     data: {
    //       code: employeeResp?.code,
    //     },
    //   });
    //   license = userResp?.license;
    // }
    // anesthesiologist = doctorResp?.id;
    // anesthesiologistLicense = license;
    // anesthesiologistCode = employeeResp?.code;
    // }

    // set DX, ProposedOperation, BloodPrepared, Surgeon, DateTime
    const aneOrderId = state.selectedOrOrder?.other_orders?.find(
      (item: any) => item.model === "anesthesiaorder"
    )?.id;

    let aneDataResp = null;

    if (state.selectedOrOrder?.encounter) {
      // && aneOrderId
      [aneDataResp] = await AnesthesiaDataList.list({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          ans_order_id: aneOrderId,
        },
      });
    }

    const mainIndex = operatingOrder?.teams?.findIndex((team: any) => team.is_main);
    let preAnestheticData: Record<string, any> | undefined =
      StateInitial.PreAnestheticSequence?.data;
    let preAnesthetic: Record<string, any> = {};

    if (aneDataResp) {
      preAnestheticData = {
        ...preAnestheticData,
        ...measurementData,
        DX: aneDataResp?.pre_diagnosis || "",
        ProposedOperation: aneDataResp?.pre_operation || "",
        BloodPrepared: aneDataResp?.blood_request || "",
        Surgeon: aneDataResp?.primary_doctor_name || "",
        DateTime: aneDataResp?.or_datetime || "",
        // Anesthesiologist: preRecordResp?.data?.Anesthesiologist,
        // AnesthesiaNurse: preRecordResp?.data?.AnesthesiaNurse,
        // AnesthesiologistLicenseNumber: preRecordResp?.data?.Anesthesiologist
        //   ? preRecordResp?.data?.AnesthesiologistLicenseNumber
        //   : anesthesiologistLicense,
        // AnesthesiologistCode: preRecordResp?.data?.Anesthesiologist
        //   ? preRecordResp?.data?.AnesthesiologistCode
        //   : anesthesiologistCode,
      };
    }

    const management = formProblemsResp?.data?.PlanOfPostoperativePainManagement;
    const planOfPostoperativePainManagement =
      management && typeof management === "string" ? [management] : management;

    if (preRecordErr || !preRecordResp?.data) {
      preAnesthetic = {
        ...state.PreAnestheticSequence,
        preAnestheticHistoryDetail: {
          id: formHistoryResp?.id,
          edit_user_name: formHistoryResp?.edit_user_name,
          edited: formHistoryResp?.edited,
          status: formHistoryResp?.status,
        },
        preAnestheticAirwayDetail: {
          id: formAirwayResp?.id,
          edit_user_name: formAirwayResp?.edit_user_name,
          edited: formAirwayResp?.edited,
          status: formAirwayResp?.status,
        },
        preAnestheticBloodDetail: {
          id: formBloodResp?.id,
          edit_user_name: formBloodResp?.edit_user_name,
          edited: formBloodResp?.edited,
          status: formBloodResp?.status,
        },
        preAnestheticProblemsDetail: {
          id: formProblemsResp?.id,
          edit_user_name: formProblemsResp?.edit_user_name,
          edited: formProblemsResp?.edited,
          status: formProblemsResp?.status,
        },
        preAnestheticRiskDetail: {
          id: formRiskResp?.id,
          edit_user_name: formRiskResp?.edit_user_name,
          edited: formRiskResp?.edited,
          status: formRiskResp?.status,
        },
        preAnestheticReEvaluationRespDetail: {
          id: formReEvaluationResp?.id,
          edit_user_name: formReEvaluationResp?.edit_user_name,
          edited: formReEvaluationResp?.edited,
          status: formReEvaluationResp?.status,
        },
        anesPhysicianEvaluation:
          {
            ...formPhysicianResp?.data,
            id: formPhysicianResp?.id,
            edit_user_name: formPhysicianResp?.edit_user_name,
            edited: formPhysicianResp?.edited,
            status: formPhysicianResp?.status,
          } || {},
        data: {
          ...preAnestheticData,
          ...preRecordResp?.data,
          ...formHistoryResp?.data,
          ...formAirwayResp?.data,
          ...formBloodResp?.data,
          ...formProblemsResp?.data,
          ...formRiskResp?.data,
          ...formReEvaluationResp?.data,
          Anesthesiologist: operatingOrder?.anesthesia_teams?.[mainIndex]?.anesthesiologist || null,
          AnesthesiaNurse: operatingOrder?.anesthesia_teams?.[mainIndex]?.anesthetists || null,
          PlanOfPostoperativePainManagement: planOfPostoperativePainManagement,
        },
      };
    } else if (preRecordResp.data) {
      const initial = preRecordResp.status_label !== "Confirm";

      let vitalSignsData = {
        ...preRecordResp?.data,
        BodyWeight: initial ? measurementData?.BodyWeight : preRecordResp?.data?.BodyWeight,
        Height: initial ? measurementData?.Height : preRecordResp?.data?.Height,
        BMI: initial ? measurementData?.BMI : preRecordResp?.data?.BMI,
        BP: initial ? measurementData?.BP : preRecordResp?.data?.BP,
        Pulse: initial ? measurementData?.Pulse : preRecordResp?.data?.Pulse,
        RR: initial ? measurementData?.RR : preRecordResp?.data?.RR,
        PsychologicalStatusTemp: initial
          ? measurementData?.PsychologicalStatusTemp
          : preRecordResp?.data?.PsychologicalStatusTemp,
        O2SatRA: initial ? measurementData?.O2SatRA : preRecordResp?.data?.O2SatRA,
      };

      preAnesthetic = {
        ...state.PreAnestheticSequence,
        ...preRecordResp,
        preAnestheticHistoryDetail: {
          id: formHistoryResp?.id,
          edit_user_name: formHistoryResp?.edit_user_name,
          edited: formHistoryResp?.edited,
          status: formHistoryResp?.status,
        },
        preAnestheticAirwayDetail: {
          id: formAirwayResp?.id,
          edit_user_name: formAirwayResp?.edit_user_name,
          edited: formAirwayResp?.edited,
          status: formAirwayResp?.status,
        },
        preAnestheticBloodDetail: {
          id: formBloodResp?.id,
          edit_user_name: formBloodResp?.edit_user_name,
          edited: formBloodResp?.edited,
          status: formBloodResp?.status,
        },
        preAnestheticProblemsDetail: {
          id: formProblemsResp?.id,
          edit_user_name: formProblemsResp?.edit_user_name,
          edited: formProblemsResp?.edited,
          status: formProblemsResp?.status,
        },
        preAnestheticRiskDetail: {
          id: formRiskResp?.id,
          edit_user_name: formRiskResp?.edit_user_name,
          edited: formRiskResp?.edited,
          status: formRiskResp?.status,
        },
        preAnestheticReEvaluationRespDetail: {
          id: formReEvaluationResp?.id,
          edit_user_name: formReEvaluationResp?.edit_user_name,
          edited: formReEvaluationResp?.edited,
          status: formReEvaluationResp?.status,
        },
        anesPhysicianEvaluation:
          {
            ...formPhysicianResp?.data,
            id: formPhysicianResp?.id,
            edit_user_name: formPhysicianResp?.edit_user_name,
            edited: formPhysicianResp?.edited,
            status: formPhysicianResp?.status,
          } || {},
        data: {
          ...(initial ? vitalSignsData : state.PreAnestheticSequence?.data),
          ...vitalSignsData,
          ...formHistoryResp?.data,
          ...formAirwayResp?.data,
          ...formBloodResp?.data,
          ...formProblemsResp?.data,
          ...formRiskResp?.data,
          ...formReEvaluationResp?.data,
          ...(initial
            ? {
                PlanOfPostoperativePainManagement: planOfPostoperativePainManagement,
                PreanestheticAssessmentAt: preRecordResp?.data.PreanestheticAssessmentAt,
                PreanestheticAssessmentAtRemark:
                  preRecordResp?.data.PreanestheticAssessmentAtRemark,
                PsychologicalStatus: preRecordResp?.data.PsychologicalStatus,
                PsychologicalStatusRemark: preRecordResp?.data.PsychologicalStatusRemark,
              }
            : {}),
        },
      };
    }

    let listWithKey = state.searchedItemListWithKey || {};

    removeListWithKey(listWithKey, SEARCH_INDEXES);

    operatingOrder.anesthesia_teams[mainIndex] = {
      anesthesiologist: preAnesthetic?.data.Anesthesiologist,
      anesthetists: preAnesthetic?.data.AnesthesiaNurse?.filter(Boolean),
    };

    const key = await getListWithKey(controller as any, {
      order: operatingOrder,
      listWithKey,
      keys: ["anesthesiologist", "anesthetists"],
    });

    listWithKey = { ...listWithKey, ...key };

    controller.setState({
      PreAnestheticSequence: preAnesthetic,
      searchedItemListWithKey: listWithKey,
    });
  } else if ([FORM_DATA_ACTION.SAVE, FORM_DATA_ACTION.CONFIRM].includes(params?.action)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;
    let preAnesData = state.PreAnestheticSequence?.data;
    var preID =
      params.formCode === "CardPreAnestheticHistory"
        ? state.PreAnestheticSequence?.preAnestheticHistoryDetail?.id
        : params.formCode === "CardPreAnestheticAirwayEvaluation"
        ? state.PreAnestheticSequence?.preAnestheticAirwayDetail?.id
        : params.formCode === "CardPreAnestheticBloodChemistry"
        ? state.PreAnestheticSequence?.preAnestheticBloodDetail?.id
        : params.formCode === "CardPreAnestheticProblemsConsiderations"
        ? state.PreAnestheticSequence?.preAnestheticProblemsDetail?.id
        : params.formCode === "CardPreAnestheticRisk"
        ? state.PreAnestheticSequence?.preAnestheticRiskDetail?.id
        : params.formCode === "CardPreAnestheticReEvaluation"
        ? state.PreAnestheticSequence?.preAnestheticReEvaluationRespDetail?.id
        : "";

    const dataPreAnesthetic = {
      PreanestheticAssessmentAt: preAnesData?.PreanestheticAssessmentAt,
      PreanestheticAssessmentAtRemark: preAnesData?.PreanestheticAssessmentAtRemark,
      BodyWeight: preAnesData?.BodyWeight,
      Height: preAnesData?.Height,
      BMI: preAnesData?.BMI,
      BP: preAnesData?.BP,
      PsychologicalStatus: preAnesData?.PsychologicalStatus,
      PsychologicalStatusRemark: preAnesData?.PsychologicalStatusRemark,
      Pulse: preAnesData?.Pulse,
      RR: preAnesData?.RR,
      PsychologicalStatusTemp: preAnesData?.PsychologicalStatusTemp,
      O2SatRA: preAnesData?.O2SatRA,
      DX: preAnesData?.DX,
      ProposedOperation: preAnesData?.ProposedOperation,
      Surgeon: preAnesData?.Surgeon,
      DateTime: preAnesData?.DateTime,
      Anesthesiologist: preAnesData?.Anesthesiologist,
      AnesthesiologistLicenseNumber: preAnesData?.AnesthesiologistLicenseNumber,
      AnesthesiologistCode: preAnesData?.AnesthesiologistCode,
      AnesthesiaNurse: preAnesData?.AnesthesiaNurse?.filter(Boolean),
    };

    const dataHistory = {
      PreviousAnesthesia: preAnesData?.PreviousAnesthesia,
      PreviousAnesthesiaRemark: preAnesData?.PreviousAnesthesiaRemark,
      Complication: preAnesData?.Complication,
      ComplicationRemark: preAnesData?.ComplicationRemark,
      KnownDisease: preAnesData?.KnownDisease,
      KnownDiseaseRemark: preAnesData?.KnownDiseaseRemark,
      FamilyHistory: preAnesData?.FamilyHistory,
      FamilyHistoryRemark: preAnesData?.FamilyHistoryRemark,
      CurrentMedications: preAnesData?.CurrentMedications,
      CurrentMedicationsRemark: preAnesData?.CurrentMedicationsRemark,
      HabitIsNon: preAnesData?.HabitIsNon,
      HabitAlcohol: preAnesData?.HabitAlcohol,
      HabitAlcoholRemark: preAnesData?.HabitAlcoholRemark,
      HabitSmoking: preAnesData?.HabitSmoking,
      HabitSmokingRemark: preAnesData?.HabitSmokingRemark,
      HabitDrugAbuse: preAnesData?.HabitDrugAbuse,
      HabitDrugAbuseRemark: preAnesData?.HabitDrugAbuseRemark,
      HabitHerbSupplement: preAnesData?.HabitHerbSupplement,
      HabitHerbSupplementRemark: preAnesData?.HabitHerbSupplementRemark,
      FunctionalClass: preAnesData?.FunctionalClass,
      FunctionalLMP: preAnesData?.FunctionalLMP,
      FunctionalIsContactLens: preAnesData?.FunctionalIsContactLens,
    };

    const dataAirway = {
      MallampatiGrade: preAnesData?.MallampatiGrade,
      ThyromentalDistance: preAnesData?.ThyromentalDistance,
      MouthOpening: preAnesData?.MouthOpening,
      PatencyOfNares: preAnesData?.PatencyOfNares,
      ProminentIncisor: preAnesData?.ProminentIncisor,
      LimitationOfHeadNeckMotility: preAnesData?.LimitationOfHeadNeckMotility,
      DifficultIntubationExpected: preAnesData?.DifficultIntubationExpected,
      Comment: preAnesData?.Comment,
      ArtificialAirway: preAnesData?.ArtificialAirway,
      ArtificialAirwayIsCommentTube: preAnesData?.ArtificialAirwayIsCommentTube,
      Dental: preAnesData?.Dental,
      Respiratory: preAnesData?.Respiratory,
      RespiratoryRemark: preAnesData?.RespiratoryRemark,
      Cardiovascular: preAnesData?.Cardiovascular,
      CardiovascularRemark: preAnesData?.CardiovascularRemark,
      Neurologic: preAnesData?.Neurologic,
      NeurologicRemark: preAnesData?.NeurologicRemark,
      EENT: preAnesData?.EENT,
      EENTRemark: preAnesData?.EENTRemark,
      GI: preAnesData?.GI,
      GIRemark: preAnesData?.GIRemark,
      Genitourinary: preAnesData?.Genitourinary,
      GenitourinaryRemark: preAnesData?.GenitourinaryRemark,
      EndocrineAndMetabolic: preAnesData?.EndocrineAndMetabolic,
      EndocrineAndMetabolicRemark: preAnesData?.EndocrineAndMetabolicRemark,
      SkinBreast: preAnesData?.SkinBreast,
      SkinBreastRemark: preAnesData?.SkinBreastRemark,
      ImmunologyHematology: preAnesData?.ImmunologyHematology,
      ImmunologyHematologyRemark: preAnesData?.ImmunologyHematologyRemark,
    };

    const dataBlood = {
      HbTime: preAnesData?.HbTime,
      HcTime: preAnesData?.HcTime,
      PltTime: preAnesData?.PltTime,
      WBCTime: preAnesData?.WBCTime,
      PTTime: preAnesData?.PTTime,
      PTTTime: preAnesData?.PTTTime,
      INRTime: preAnesData?.INRTime,
      NaTime: preAnesData?.NaTime,
      KTime: preAnesData?.KTime,
      ClTime: preAnesData?.ClTime,
      CO2Time: preAnesData?.CO2Time,
      BunTime: preAnesData?.BunTime,
      CrTime: preAnesData?.CrTime,
      GFRTime: preAnesData?.GFRTime,
      BSTIME: preAnesData?.BSTIME,
      ASTTime: preAnesData?.ASTTime,
      ALTTime: preAnesData?.ALTTime,
      AlpTime: preAnesData?.AlpTime,
      AlbTime: preAnesData?.AlbTime,
      OthersTime: preAnesData?.OthersTime,
      CXR: preAnesData?.CXR,
      EKG: preAnesData?.EKG,
      Imaging: preAnesData?.Imaging,
    };

    const dataProblems = {
      AnestheticProblemsConsiderationsRemark: preAnesData?.AnestheticProblemsConsiderationsRemark,
      IsEmergency: preAnesData?.IsEmergency,
      ASAPSClassification: preAnesData?.ASAPSClassification,
      IsAnestheticInformedConsent: preAnesData?.IsAnestheticInformedConsent,
      NPO: preAnesData?.NPO,
      AnestheicPlan: preAnesData?.AnestheicPlan,
      AlternativeOfAnesthesiaDiscussed: preAnesData?.AlternativeOfAnesthesiaDiscussed,
      PremedicationNone: preAnesData?.PremedicationNone,
      PremedicationNoneRemark: preAnesData?.PremedicationNoneRemark,
      PremedicationNoneRemarkOther: preAnesData?.PremedicationNoneRemarkOther,
      BloodPrepared: preAnesData?.BloodPrepared,
      BloodPreparedStatus: preAnesData?.BloodPreparedStatus,
      PlanOfPostoperativeCare: preAnesData?.PlanOfPostoperativeCare,
      AmbulatoryPatientWithCompanion: preAnesData?.AmbulatoryPatientWithCompanion,
      PlanOfPostoperativePainManagement: preAnesData?.PlanOfPostoperativePainManagement,
      PlanOfPostoperativePainManagementRemark: preAnesData?.PlanOfPostoperativePainManagementRemark,
    };

    const dataRisk = {
      SoreThroat: preAnesData?.SoreThroat,
      Hoarseness: preAnesData?.Hoarseness,
      Nausea: preAnesData?.Nausea,
      Vomiting: preAnesData?.Vomiting,
      Pruritus: preAnesData?.Pruritus,
      UrinaryRetention: preAnesData?.UrinaryRetention,
      VentilatorySupport: preAnesData?.VentilatorySupport,
      RiskOfAnesthesiaIncludedOther: preAnesData?.RiskOfAnesthesiaIncludedOther,
      RiskOfAnesthesiaIncludedOtherRemark: preAnesData?.RiskOfAnesthesiaIncludedOtherRemark,
      RiskOfAnesthesiaIncludedComment: preAnesData?.RiskOfAnesthesiaIncludedComment,
    };

    const dataReEvaluation = {
      PreanestheticReEvaluationNoneDueto: preAnesData?.PreanestheticReEvaluationNoneDueto,
      PreanestheticReEvaluationIsNoneDueto: preAnesData?.PreanestheticReEvaluationIsNoneDueto,
      PREANESTHETICREEVALUATIONDateTime: `${preAnesData?.PREANESTHETICREEVALUATIONDate} [${preAnesData?.PREANESTHETICREEVALUATIONTime}]`,
      PREANESTHETICREEVALUATIONDate: preAnesData?.PREANESTHETICREEVALUATIONDate,
      PREANESTHETICREEVALUATIONTime: preAnesData?.PREANESTHETICREEVALUATIONTime,
      PREANESTHETICREEVALUATIONBodyWeight: preAnesData?.PREANESTHETICREEVALUATIONBodyWeight,
      PREANESTHETICREEVALUATIONBP: preAnesData?.PREANESTHETICREEVALUATIONBP,
      PreanestheticReEvaluationIsPR: preAnesData?.PreanestheticReEvaluationIsPR,
      PreanestheticReEvaluationIsRR: preAnesData?.PreanestheticReEvaluationIsRR,
      PreanestheticReEvaluationTemp: preAnesData?.PreanestheticReEvaluationTemp,
      PreanestheticReEvaluationLMP: preAnesData?.PreanestheticReEvaluationLMP,
      PreanestheticReEvaluationProposedOperation:
        preAnesData?.PreanestheticReEvaluationProposedOperation,
      IsChangeInClinicalCondition: preAnesData?.IsChangeInClinicalCondition,
      ChangeInClinicalConditionRemark: preAnesData?.ChangeInClinicalConditionRemark,
      IsChangeInCurrentMedication: preAnesData?.IsChangeInCurrentMedication,
      ChangeInCurrentMedicationRemark: preAnesData?.ChangeInCurrentMedicationRemark,
      IsNewDiseaseIllness: preAnesData?.IsNewDiseaseIllness,
      NewDiseaseIllnessRemark: preAnesData?.NewDiseaseIllnessRemark,
      AssessmentIsStableClinicalCondition: preAnesData?.AssessmentIsStableClinicalCondition,
      AssessmentIsUnstableDueTo: preAnesData?.AssessmentIsUnstableDueTo,
      AssessmentUnstableDueToRemark: preAnesData?.AssessmentUnstableDueToRemark,
      PlanIsGoOnAnesthesiaAsPlan: preAnesData?.PlanIsGoOnAnesthesiaAsPlan,
      PlanIsOther: preAnesData?.PlanIsOther,
      PlanOtherRemark: preAnesData?.PlanOtherRemark,
      PreanestheticReEvaluationAnesthetsiologisAnesthetist:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetist,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistId:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetistId,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetistCode,
      PreanestheticReEvaluationAnesthesiaNurseId:
        preAnesData?.PreanestheticReEvaluationAnesthesiaNurseId,
      PreanestheticReEvaluationAnesthesiaNurse:
        preAnesData?.PreanestheticReEvaluationAnesthesiaNurse,
      PreanestheticReEvaluationAnesthesiaNurseLicenseNumber:
        preAnesData?.PreanestheticReEvaluationAnesthesiaNurseLicenseNumber,
      PreanestheticReEvaluationAnesthesiaNurseCode:
        preAnesData?.PreanestheticReEvaluationAnesthesiaNurseCode,
    };

    const data = {
      action: params?.action, // SAVE
      data:
        params.formCode === "CardPreAnestheticHistory"
          ? dataHistory
          : params.formCode === "CardPreAnestheticAirwayEvaluation"
          ? dataAirway
          : params.formCode === "CardPreAnestheticBloodChemistry"
          ? dataBlood
          : params.formCode === "CardPreAnestheticProblemsConsiderations"
          ? dataProblems
          : params.formCode === "CardPreAnestheticRisk"
          ? dataRisk
          : params.formCode === "CardPreAnestheticReEvaluation"
          ? dataReEvaluation
          : "",
      encounter: state.selectedOrOrder?.encounter,
      form_code: params?.formCode,
      form_name: params?.formName,
      form_version: params?.formVersion,
    };

    if (params.tabForm) {
      if (preID !== "" && preID !== undefined) {
        [saveResp, saveErr, saveNet] = await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: preID,
          data: data,
          extra: {
            division: controller.data.division,
          },
        });
      } else {
        [saveResp, saveErr, saveNet] = await FormDataList.create({
          apiToken: controller.apiToken,
          data: data,
          extra: {
            division: controller.data.division,
          },
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_PRE_ANESTHETIC" });
      }
    } else {
      if (state.PreAnestheticSequence?.id) {
        [saveResp, saveErr, saveNet] = await PreAnestheticRecordDetail.update({
          apiToken: controller.apiToken,
          pk: state.PreAnestheticSequence?.id,
          data: {
            action: params.action, // SAVE, CONFIRM
            data: dataPreAnesthetic,
            // state.PreAnestheticSequence?.data,
            encounter: state.selectedOrOrder?.encounter,
            form_code: params.formCode,
            form_version: params.formVersion,
          },
          extra: {
            division: controller.data.division,
          },
        });
      } else {
        [saveResp, saveErr, saveNet] = await PreAnestheticRecordList.create({
          apiToken: controller.apiToken,
          data: {
            action: params.action, // SAVE, CONFIRM
            data: dataPreAnesthetic,
            encounter: state.selectedOrOrder?.encounter,
            form_code: params.formCode,
            form_version: params.formVersion,
          },
          extra: {
            division: controller.data.division,
          },
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_PRE_ANESTHETIC" });
      }
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PreAnestheticSequence?.id) {
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.PreAnestheticSequence?.id,
          pdf: true,
        },
        extra: {
          division: controller.data.division,
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.selectedOrOrder?.encounter) {
      const [logResp, logErr, logNet] = await PreAnestheticLogList.list({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_version: params.formVersion,
        },
        extra: {
          division: controller.data.division,
        },
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          PreAnestheticSequence: {
            ...state.PreAnestheticSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Encounter",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    // #let userResp: any = null;
    // #let userErr: any = null;
    const paramKey = params.searchBox?.name || "";

    let employeeCode = params.data?.employee_code;
    let licence = params.data?.licence;

    if (params.data) {
      const [employee] = await EmployeeDetailByUsername.get({
        apiToken: controller.apiToken,
        username: params.data?.username,
      });

      employeeCode = employee?.code;
      licence = employee?.licence;
    }

    // #if (employeeCode) {
    //   [userResp, userErr] = await UserTokenizeLicenseView.post({
    //     apiToken: controller.apiToken,
    //     data: {
    //       code: employeeCode,
    //     },
    //     extra: {
    //       division: controller.data.division,
    //     },
    //   });
    // }

    const data: any = { ...state.PreAnestheticSequence?.data };

    data[paramKey] = params.data?.full_name || "";
    data[`${paramKey}LicenseNumber`] = licence || "";
    data[`${paramKey}Code`] = employeeCode || "";
    data[`${paramKey}Id`] = params.data?.id || null;

    // #if (userResp) {
    //   const encodedName = userResp.token.split(".")[1];
    //   data[params.paramKey] = decodeURIComponent(atob(encodedName));
    //   data[`${params.paramKey}LicenseNumber`] = userResp.license;
    //   data[`${params.paramKey}Code`] = params.data.employee_code;
    //   data[`${params.paramKey}Id`] = params.data?.id;
    // } else {
    //   data[params.paramKey] = "";
    //   data[`${params.paramKey}LicenseNumber`] = "";
    //   data[`${params.paramKey}Code`] = "";
    //   data[`${params.paramKey}Id`] = null;
    //   controller.setState({
    //     errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
    //   });
    // }

    //check props.PreAnestheticSequence?.data?.Anesthesiologist
    controller.setState({
      PreAnestheticSequence: {
        ...state.PreAnestheticSequence,
        data,
      },
    });
  } else if (params?.action === "GET_LICENSE") {
    // Get Employee Code
    const [employeeResp, employeeErr, employeeNet] = await UserEmployeeDetailAPIView.retrieve({
      pk: params.paramKey,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    // Get License
    if (employeeResp?.code) {
      const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
        apiToken: controller.apiToken,
        data: {
          code: employeeResp?.code,
        },
        extra: {
          division: controller.data.division,
        },
      });

      let data: any = { ...state.PreAnestheticSequence?.data };

      if (userErr) {
        data[`${params.cardKey}LicenseNumber`] = "";
        data[`${params.cardKey}Code`] = "";

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
        });
      } else {
        data[`${params.cardKey}LicenseNumber`] = userResp.license;
        data[`${params.cardKey}Code`] = employeeResp.code;
      }

      controller.setState({
        PreAnestheticSequence: {
          ...state.PreAnestheticSequence,
          data: data,
        },
      });
    }
  } else if (params.action === "getPhysicianEvaluation") {
    const state = controller.getState();

    const [physicianResp, physicianErr, physicianNet] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedOrOrder?.encounter,
        form_code: "CardPhysicianEvaluation",
        form_version: "1.0",
      },
      extra: { division: controller.data.division },
    });

    controller.setState({
      PreAnestheticSequence: {
        ...state.PreAnestheticSequence,
        anesPhysicianEvaluation: {
          ...physicianResp?.data,
          id: physicianResp?.id,
          edit_user_name: physicianResp?.edit_user_name,
          edited: physicianResp?.edited,
          status: physicianResp?.status,
        },
      },
    });
  } else if (params.action === "saveAnesPhysicianEvaluation") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let anesPhysicianEvaluation = state.PreAnestheticSequence?.anesPhysicianEvaluation;

    const api = anesPhysicianEvaluation?.id ? FormDataDetail.update : FormDataList.create;
    const data = {
      data: {
        ...anesPhysicianEvaluation,
      },
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "CardPhysicianEvaluation",
      form_name: "CardPhysicianEvaluation",
      form_version: "1.0",
    };

    const [physicianResp, physicianErr, physicianNet] = await api({
      apiToken: controller.apiToken,
      pk: anesPhysicianEvaluation?.id,
      data: data,
    });

    if (physicianResp) {
      Action(controller, { action: "getPhysicianEvaluation" });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "INIT_ANESTHETIC_PROBLEMS") {
    const seq = state.PreAnestheticSequence || {};

    if (!seq.preAnestheticProblemsID) {
      controller.setState({
        PreAnestheticSequence: {
          ...state.PreAnestheticSequence,
          data: {
            ...seq.data,
            AnestheticProblemsConsiderationsRemark: seq.anesPhysicianEvaluation?.considerations,
            ASAPSClassification: seq.anesPhysicianEvaluation?.classification,
            IsEmergency: seq.anesPhysicianEvaluation?.emergencyCheck,
            PlanOfPostoperativePainManagement: seq.anesPhysicianEvaluation?.management,
          },
        },
      });
    }
  }
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
export const GetMeasurementData: Handler = async (controller, params) => {
  const state = controller.getState();

  const [measurementResp] = await MeasurementList.list({
    params: {
      ...params,
      sort_by_seq: true,
      use_patient: true,
      invert_order: true,
      encounter: state.selectedOrOrder?.encounter,
    },
    apiToken: controller.apiToken,
  });

  let measurement: any[] = measurementResp?.items[0]?.vital_signs || [];
  let BMI = "";
  let Weight = "";
  let Height = "";
  let TEMP = "";
  let SP = "";
  let DP = "";
  let PULSE = "";
  let O2Sat = "";
  let RR = "";

  measurement.forEach((item: any) => {
    if (item.vitalsign_type_code === "BMI") {
      BMI = item.formatted_string;
    } else if (item.vitalsign_type_code === "Weight") {
      Weight = item.formatted_string;
    } else if (item.vitalsign_type_code === "Height") {
      Height = item.formatted_string;
    } else if (["TEMP", "BT"].includes(item.vitalsign_type_code)) {
      TEMP = item.formatted_string;
    } else if (item.vitalsign_type_code === "SP") {
      SP = item.formatted_string;
    } else if (item.vitalsign_type_code === "DP") {
      DP = item.formatted_string;
    } else if (["PULSE", "PR"].includes(item.vitalsign_type_code)) {
      PULSE = item.formatted_string;
    } else if (item.vitalsign_type_code === "O2Sat") {
      O2Sat = item.formatted_string;
    } else if (item.vitalsign_type_code === "RR") {
      RR = item.formatted_string;
    }
  });

  const measurementData = {
    BodyWeight: Weight,
    Height: Height,
    BMI: BMI,
    BP: `${SP} / ${DP}`,
    Pulse: PULSE,
    RR: RR,
    PsychologicalStatusTemp: TEMP,
    O2SatRA: O2Sat,
  };

  return measurementData;
};
