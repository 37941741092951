import * as PreAnestheticI from "./PreAnesthetic";
import * as PACURecordI from "./PACURecord";
import * as PeriDataSheetI from "./PeriDataSheet";
import * as AnesComplicationI from "./AnesComplication";
import * as AnesRecordI from "./AnesRecord";
import * as AnesBillI from "./AnesBill";
import * as AnesTemplateI from "./AnesTemplate";
import * as AnesDoctorFeeListI from "./AnesDoctorFeeList";
import * as AnestheticHistoryI from "./AnestheticHistory";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  PreAnesthetic: {
    default: {
      START: PreAnestheticI.GetMaster,
      Action: PreAnestheticI.Action,
    },
  },
  PACURecord: {
    default: {
      START: PACURecordI.GetMaster,
      Action: PACURecordI.Action,
    },
  },
  PeriDataSheet: {
    default: {
      START: PeriDataSheetI.GetMaster,
      Action: PeriDataSheetI.Action,
    },
  },
  AnesComplication: {
    default: {
      START: AnesComplicationI.GetMaster,
      Action: AnesComplicationI.Action,
    },
  },
  AnesRecord: {
    default: {
      START: AnesRecordI.GetMaster,
      Action: AnesRecordI.Action,
    },
  },
  AnesBill: {
    default: {
      START: AnesBillI.GetMaster,
      Action: AnesBillI.Action,
    },
  },
  AnesTemplate: {
    default: {
      START: AnesTemplateI.GetMaster,
      Action: AnesTemplateI.Action,
    },
  },
  AnesDoctorFeeList: {
    default: {
      START: AnesDoctorFeeListI.Start,
      Action: AnesDoctorFeeListI.Action,
    },
  },
  AnestheticHistory: {
    default: {
      START: AnestheticHistoryI.Start,
    },
  },
};

export default SequencePattern;
